.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.logo {
  width: 100px;
  height: 55px;
  margin: 16px 24px 16px 0;
  float: left;
  background-size: contain;
  background-repeat: no-repeat;
}

.menu-style {
  height: 200px;
}

.headcontrols{
  padding-left: 2em;
}

.ant-table {
  display: block;

  @media screen and (max-width: 730px) {
    &-thead {
      display: none;
    }

    &-thead>tr,
    &-tbody>tr {

      th,
      td {
        &:first-of-type {
          padding-top: 1rem;
        }

        &:last-of-type {
          padding-bottom: 1rem;
        }
      }

      >th,
      >td {
        display: block;
        width: auto !important;
        border: none;
        padding: 0 1rem;
        font-size: 1.1rem;

        &:last-child {
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
}